
import {defineComponent} from "vue";
import {useField, useForm} from "vee-validate";
import {example3Html, example3Js,} from "@/view/pages/resources/documentation/forms/vee-validate/codes";
import CodeHighlighter2 from "@/view/content/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "example-3",
  components: {
    CodeHighlighter2,
  },
  setup() {
    // Define a validation schema
    const simpleSchema = {
      email(value) {
        if (value && value.trim()) {
          return true;
        }

        return "Email is required";
      },
      password(value) {
        if (value && value.trim()) {
          return true;
        }

        return "Password is required";
      },
    };

    // Create a form context with the validation schema
    useForm({
      validationSchema: simpleSchema,
    });

    // No need to define rules for fields
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } =
      useField("password");

    return {
      email,
      emailError,
      password,
      passwordError,
      example3Html,
      example3Js,
    };
  },
});
