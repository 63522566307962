
import {defineComponent} from "vue";
import {useField} from "vee-validate";
import CodeHighlighter2 from "@/view/content/highlighters/CodeHighlighter2.vue";
import {example1Html, example1Js,} from "@/view/pages/resources/documentation/forms/vee-validate/codes";

export default defineComponent({
  name: "example-1",
  components: {
    CodeHighlighter2,
  },
  setup() {
    const isRequired = (value) => {
      if (value && value.trim()) {
        return true;
      }

      return "This is required";
    };

    const { errorMessage, value } = useField("fieldName", isRequired);

    return {
      errorMessage,
      value,
      example1Html,
      example1Js,
    };
  },
});
